import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Home/HomePage';
import AboutUs from './AboutUs/AboutUs'
import PlaylistVideos from './PlaylistVideos/PlaylistVideos';
import Videos from './Video/Videos';


function App() {
  return (
    <Router>
      <Routes>
       <Route path='/' element={<HomePage/>}/>
       <Route path="/playlist/:playlistId" element={<PlaylistVideos/>}/>
       <Route path='aboutus' element={<AboutUs/>}/>
       <Route path='videos' element={<Videos/>}/>
       
      </Routes>
    </Router>
  );
}

export default App;
