import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Videos.css';

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [prevPageToken, setPrevPageToken] = useState(null);

  const API_KEY = process.env.REACT_APP_API_KEY;; // Add your API key here
  const CHANNEL_ID = process.env.REACT_APP_CHANNEL_ID; // Add your Channel ID here

  // Fetch Videos from YouTube API
  const fetchVideos = async (pageToken = '') => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search`, 
        {
          params: {
            key: API_KEY,
            channelId: CHANNEL_ID,
            part: 'snippet,id',
            order: 'date',
            maxResults: 12, // Reduced to show more pages with smaller cards
            pageToken: pageToken,
          },
        }
      );

      // Set videos to replace the current ones
      setVideos(response.data.items);

      // Update page tokens
      setNextPageToken(response.data.nextPageToken || null);
      setPrevPageToken(pageToken ? response.data.prevPageToken || null : null);

      setLoading(false);

      // Scroll to top smoothly when the page changes
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error fetching videos:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos(); // Fetch the first page of videos when the component loads
  }, []);

  return (
    <div className="video-container">
      <h2>Channel Videos</h2>
      {loading ? (
        <p>Loading videos...</p>
      ) : (
        <>
          <div className="video-grid">
            {videos.map((video) => (
              <div className="video-card" key={video.id.videoId}>
                <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
                  <img
                    src={video.snippet.thumbnails.high.url}
                    alt={video.snippet.title}
                    className="video-thumbnail"
                  />
                  <div className="video-info">
                    <h3 className="video-title">{video.snippet.title}</h3>
                    <p className="video-description">{video.snippet.description}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            {prevPageToken && (
              <button className="pagination-button" onClick={() => fetchVideos(prevPageToken)}>
                Previous
              </button>
            )}
            {nextPageToken && (
              <button className="pagination-button" onClick={() => fetchVideos(nextPageToken)}>
                Next
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Videos;
