import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './HomePage.css';
import logo from './log.png'


function HomePage() {
  const API_KEY = process.env.REACT_APP_API_KEY;; // Add your API key here
  const CHANNEL_ID = process.env.REACT_APP_CHANNEL_ID; // Add your Channel ID here

  const [featuredVideo, setFeaturedVideo] = useState(null);
  const [latestVideos, setLatestVideos] = useState([]); // State for latest videos
  const [playlists, setPlaylists] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingFeatured, setLoadingFeatured] = useState(true);
  const [loadingLatest, setLoadingLatest] = useState(true);
  const [loadingPlaylists, setLoadingPlaylists] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Fetch the most recent video from the channel
  useEffect(() => {
    const fetchRecentVideo = async () => {
      try {
        setLoadingFeatured(true);
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&order=date&part=snippet&type=video&maxResults=1`
        );
        const data = await response.json();
        if (data.items && data.items.length > 0) {
          setFeaturedVideo(data.items[0]);
        } else {
          setError('No recent video found.');
        }
      } catch (error) {
        setError('Error fetching the recent video.');
      } finally {
        setLoadingFeatured(false);
      }
    };
    fetchRecentVideo();
  }, [API_KEY, CHANNEL_ID]);

  // Fetch the latest 10 videos from the channel
  useEffect(() => {
    const fetchLatestVideos = async () => {
      try {
        setLoadingLatest(true);
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&order=date&part=snippet&type=video&maxResults=10`
        );
        const data = await response.json();
        if (data.items) {
          setLatestVideos(data.items);
        } else {
          setError('No recent videos found.');
        }
      } catch (error) {
        setError('Error fetching recent videos.');
      } finally {
        setLoadingLatest(false);
      }
    };
    fetchLatestVideos();
  }, [API_KEY, CHANNEL_ID]);

  // Fetch playlists from the channel
  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        setLoadingPlaylists(true);
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlists?part=snippet&channelId=${CHANNEL_ID}&maxResults=10&key=${API_KEY}`
        );
        const data = await response.json();
        if (data.items) {
          setPlaylists(data.items);
        } else {
          setError('No playlists found.');
        }
      } catch (error) {
        setError('Error fetching playlists.');
      } finally {
        setLoadingPlaylists(false);
      }
    };
    fetchPlaylists();
  }, [API_KEY, CHANNEL_ID]);

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter playlists based on search query
  const filteredPlaylists = playlists.filter((playlist) =>
    playlist.snippet.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Navigate to the playlist's video page
  const handlePlaylistClick = (playlistId) => {
    navigate(`/playlist/${playlistId}`);
  };

  // Navigate to individual video page
  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  return (
    <div className="videos-page">
      <header className="videos-header">
        <img
          src={logo}
          alt="Logo"
        />
        <nav>
          <Link to="/">Home</Link>
          <Link to="/videos">Videos</Link>
          <Link to="/aboutus">About Us</Link>
        </nav>
        <input
          type="text"
          className="search-bar"
          placeholder="Search playlists..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </header>

      {/* Featured Section */}
      <div className="featured-section">
        {loadingFeatured ? (
          <div className="spinner"></div>
        ) : featuredVideo ? (
          <iframe
            id="featured-video"
            src={`https://www.youtube.com/embed/${featuredVideo.id.videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Featured Video"
          ></iframe>
        ) : (
          <h1>{error}</h1>
        )}
      </div>

      {/* Latest Videos Section */}
      <section className="latest-videos-section">
        <h2>Latest Videos</h2>
        {loadingLatest ? (
          <div className="spinner"></div>
        ) : latestVideos.length > 0 ? (
          <div className="latest-videos-container">
            {latestVideos.map((video) => (
              <div
                className="latest-video-card"
                key={video.id.videoId}
                onClick={() => handleVideoClick(video.id.videoId)}
              >
                <img
                  src={video.snippet.thumbnails.medium.url}
                  alt={video.snippet.title}
                />
                <div className="video-title">{video.snippet.title}</div>
              </div>
            ))}
          </div>
        ) : (
          <h2>No Videos Found...</h2>
        )}
      </section>

      {/* Playlists Section */}
      <section className="playlists-section">
        <h2>Playlists</h2>
        <div className={`playlists-grid ${filteredPlaylists.length === 0 ? 'empty' : ''}`}>
          {loadingPlaylists ? (
            <div className="spinner"></div>
          ) : filteredPlaylists.length > 0 ? (
            filteredPlaylists.map((playlist) => (
              <div
                className={`playlist-card ${searchQuery ? 'filtered-card' : ''}`}
                key={playlist.id}
                onClick={() => handlePlaylistClick(playlist.id)}
              >
                <img
                  src={playlist.snippet.thumbnails.medium.url}
                  alt={playlist.snippet.title}
                />
                <div className="playlist-title">{playlist.snippet.title}</div>
              </div>
            ))
          ) : (
            <h2>No Playlists Found...</h2>
          )}
        </div>
      </section>
    </div>
  );
}

export default HomePage;
