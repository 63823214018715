import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PlaylistVideos.css';

function PlaylistVideos() {
  const { playlistId } = useParams(); // Get playlist ID from route parameters
  const API_KEY = process.env.REACT_APP_API_KEY;; // Add your API key here
  
  const [playlistVideos, setPlaylistVideos] = useState([]);
  const [nextPageToken, setNextPageToken] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch videos from the selected playlist
  const fetchPlaylistVideos = async (pageToken = '') => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&playlistId=${playlistId}&maxResults=10&pageToken=${pageToken}&key=${API_KEY}`
      );
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error.message);
      }
      setPlaylistVideos((prevVideos) => [...prevVideos, ...data.items]);
      setNextPageToken(data.nextPageToken || '');
    } catch (error) {
      setError('Error fetching playlist videos. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Scroll to top of page when new videos are loaded
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Initial fetch
  useEffect(() => {
    fetchPlaylistVideos();
  }, [playlistId]);

  // Search Functionality
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredVideos = playlistVideos.filter((video) =>
    video.snippet.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="playlist-videos-page">
      <header className="playlist-header">
        <h2>Playlist Videos</h2>
        <div className="controls">
          <input
            type="text"
            className="search-bar"
            placeholder="Search videos..."
            value={searchQuery}
            onChange={handleSearch}
          />
          <button className="back-button" onClick={() => navigate(-1)}>
            Go Back
          </button>
        </div>
      </header>

      <div className="video-grid">
        {loading ? (
          <h2>Loading Videos...</h2>
        ) : error ? (
          <h2 className="error">{error}</h2>
        ) : filteredVideos.length > 0 ? (
          filteredVideos.map((video) => (
            <div key={video.contentDetails.videoId} className="video-card">
              <iframe
                src={`https://www.youtube.com/embed/${video.contentDetails.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={video.snippet.title}
              ></iframe>
              <div className="video-details">
                <h3 className="video-title">{video.snippet.title}</h3>
                <p className="video-description">
                  {video.snippet.description.substring(0, 100)}...
                </p>
                <span className="video-date">
                  Published on: {new Date(video.snippet.publishedAt).toDateString()}
                </span>
              </div>
            </div>
          ))
        ) : (
          <h2>No videos found</h2>
        )}
      </div>

      {nextPageToken && !loading && (
        <button
          className="load-more-btn"
          onClick={() => {
            fetchPlaylistVideos(nextPageToken);
            scrollToTop();
          }}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Load More Videos'}
        </button>
      )}
    </div>
  );
}

export default PlaylistVideos;
