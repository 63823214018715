import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-container">
      {/* Header Section */}
      <div className="about-header">
        <h1>About MangoPingo</h1>
      </div>

      {/* Description Section */}
      <div className="about-description">
        <p>
          MangoPingo channel is home for various nursery rhymes that educate and entertain toddlers. Kids will enjoy singing and dancing to our wonderful collection which includes ABC song, colors & shape song & many more!
        </p>
        <p>
          We have created videos to make learning fun, impart family values & encourage healthy habits. Parents will realize that our channel is the ideal destination for kids to acquire knowledge, develop imagination, and creativity.
        </p>
        <p><strong>Our goal is to make kids happy and smart!!!</strong></p>
      </div>

      {/* Social Links Section */}
<div className="links-section">
  <h2>Links</h2>
  <div className="social-links">
    <a href="https://facebook.com/profile.php?id=100080432646388" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-facebook-f"></i>
    </a>
    <a href="https://twitter.com/MangoPingo" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-twitter"></i>
    </a>
    <a href="https://instagram.com/mangopingo" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-instagram"></i>
    </a>
  </div>
</div>


      {/* Go Back Button at Bottom */}
      <button className="go-back-button" onClick={() => window.history.back()}>
        Go Back
      </button>
    </div>
  );
};

export default AboutUs;
